<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-2-3@s font-header">
        Detail "{{ activity.name }}"
      </div>
      <div class="uk-width-1-3@s">
        <bread-crumb :bread-crumb="breadcrumb" />
      </div>
    </div>

    <container :data-activity="activity" />
    <div id="container-modal-delete" />
    <modal-delete-confirm />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Container from './Container'
import ModalDeleteConfirm from './ModalDeleteConfirm'
import BreadCrumb from '@/components/globals/breadcrumb'

export default {
  components: {
    Container,
    ModalDeleteConfirm,
    BreadCrumb
  },
  data() {
    return {
      activity: {},
      name: 'Kategori Kegiatan',
      status: 'Detail',
      breadcrumb: [
        {
          link: '/admin/kategori-kegiatan',
          title: 'Kategori Kegiatan'
        },
        {
          link: null,
          title: 'Detail'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      activityCategoryDetail: 'activityCategory/activityCategoryDetail'
    })
  },
  async mounted() {
    await this.getActivityCategoryDetail(this.$route.params.id)
    this.activity = this.activityCategoryDetail.data
  },
  methods: {
    ...mapActions({
      getActivityCategoryDetail: 'activityCategory/getActivityCategoryDetail'
    })
  }
}
</script>
